<template>
  <list-template
      :loading="loading"
      :total="total"
      :table-data="tableData"
      :current-page="page"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onAdd="$router.push('./add')"
      @onSearch="onSearch"
      @onChangePage="onPageChange"
      @onHandle="onHandle"
  />

</template>
<script>
import listTemplate from "@/components/pages/List"
import {mapState} from "vuex";
import HistoryDialog from "@/components/HistoryDialog";
import HistoryRecord from "@/components/global/historyRecord";
import {getHistory} from "@/api/common";

export default {
  _config: {route: {path: "list", meta: {title: "列表", keepAlive: true}}},
  components: {listTemplate},
  data() {
    return {
      loading: true,
      search: {},
      // 搜索配置
      //
      searchConfig: [
          // 筛选教材版本,筛选教材名称,筛选所属学科,筛选应用学段
          {
            prop: "teach_version",
            tag: "select",
            placeholder: "筛选教材版本",
            options: []
          },
          {
            prop: "teach_name",
            tag: "select",
            placeholder: "筛选教材名称",
            options: []
          },
          {
            prop: "subject_id",
            tag: "select",
            placeholder: "筛选所属学科",
            options: []
          },
          {
            prop: "apply_type",
            tag: "select",
            placeholder: "筛选应用学段",
            options: [
                {
                  label:"初中",
                  value:0
                },
                {
                  label:"高中",
                  value:1
                }
            ]
          }
      ],
      tableConfig: [
          //教材版本,所属学科,教材名称,应用学段,章节总数,关联视频总数,操作
          {
            prop: "teach_version",
            label: "教材版本"
          },
          {
            prop: "subject_name",
            label: "所属学科"
          },
          {
            prop: "teach_name",
            label: "教材名称"
          },
          {
            prop: "apply_type",
            label: "应用学段"
          },
          {
            prop: "article_num",
            label: "章节总数"
          },
          {
            prop: "video_num",
            label: "关联视频总数"
          },
          {
            label: "操作",
            handle: true
          }
      ],
      tableData: [],
      total: 0,
      pageCount: 20,
      isInit:false
    }
  },
  mounted() {
    this.init();
    this.getData();
  },
  activated() {
    this.init();
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    async init(){
      if (this.isInit)return;
      this.isInit = true;
      let formatData = (arr)=> {
        if (Array.isArray(arr))
          return arr.map(item => ({label: item, value: item}))
        else
          return this.$tools.jsonToArray(arr,{
            key:"value",
            val:"label"
          });
      }

      // 教材版本
      await this.getSearch("teach_version").then(res=>{
        this.$utils.updateOptions.call(this,'prop','teach_version',formatData(res.data))
      })
      // 教材名称
      await this.getSearch("teach_name").then(res=>{
        this.$utils.updateOptions.call(this,'prop','teach_name',formatData(res.data));
      })
      // 所属学科
      await this.getSearch("subject_id").then(res=>{
        this.$utils.updateOptions.call(this,'prop','subject_id',formatData(res.data));
      });
      this.isInit = false;
    },
    getData() {
      this.loading = true;
      this.$_axios2.get("api/video/list-article",{
        logic:1,
        params:{
          ...this.search,
          page:this.page,
        }
      }).then(res=>{
        this.tableData = res.data.list;
        this.total = res.data.page.total;
      }).finally(()=>this.loading=false)
      // request ...
    },
    getSearch(search_type,search_value){
      return this.$_axios2.get("api/video/search-article",{
        logic:1,
        params:{
          search_type,
          search_value
        }
      })
    },
    onHandle(row, p) {
      // list button handle ...
      switch (p.type){
        case "edit":
          this.$router.push("./edit?id="+row.id);
          break;
        case "viewHistory":
          getHistory("video_article_config",row.id).then(res=>{
            this.$historyRecord({
              data:res.data.list
            })
          })
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
